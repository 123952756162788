.hero {
  padding-top: 107px;
  padding-bottom: 110px;
}

.hero__in {
  max-width: 1140px;
  margin: 0 auto;
}

.hero__title {
  margin: 0;
  margin-bottom: calc(var(--space) * 9);
  padding: 0;
  font-family: var(--font-secondary);
  font-size: 68px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: var(--default-color);
}

.hero__title svg {
  width: 100%;
  height: auto;
}

.hero__title--mobile {
  display: none;
}

.hero__title-secondary-color {
  animation: hero-title-stroke-animation 4s alternate forwards;
  stroke-width: 1;
  stroke: rgba(10, 30, 76, 1);
  fill: rgba(10, 30, 76, 0);
}

.hero__title-primary-color {
  animation: hero-title-stroke-animation-primary 4s alternate forwards;
  stroke-width: 1;
  stroke: rgba(255, 92, 41, 1);
  font-size: 120px;
  fill: rgba(255, 92, 41, 0);
}

.hero__title-text {
  display: block;
  margin-bottom: 0.3em;
}

.hero__title-text:nth-child(2) {
  text-align: right;
}

.hero__title-text:nth-child(3) {
  text-align: center;
  margin-left: -1.3em;
  margin-bottom: 0;
}

.hero__footer {
  display: flex;
  /* max-width: 813px; */
  justify-content: center;
  margin: 0 auto 108px;
}

.hero__cta {
  margin-right: 33px;
  width: 100%;
  max-width: 226px;

  .button {
    width: 100%;
  }
}

.hero__text {
  font-size: 24px;
  font-weight: 500;
  line-height: 29px;
  letter-spacing: 0;
  color: var(--default-color);
}

.hero__image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
}

.hero__image--mobile {
  display: none;
}

.hero__image-container > div {
  position: relative;
  height: 20rem;
  padding-top: 34.53125%;
}

@media screen and (max-width: 1140px) {
  .hero__in {
    max-width: 780px;
  }

  .hero__title {
    font-size: 44px;
  }
}

@media screen and (max-width: 844px) {
  .hero__title--mobile {
    display: block;
  }

  .hero__title--desktop {
    display: none;
  }

  .hero {
    padding-bottom: 36px;
    padding-top: 65px !important;
  }

  .hero__in {
    max-width: 304px;
  }

  .hero__title {
    font-size: 32px;
    line-height: 32px;
    margin-bottom: calc(var(--space) * 3);
  }

  .hero__footer {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-bottom: calc(var(--space) * 9);
  }

  .hero__text {
    margin-bottom: 24px;
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 1.2;
    max-width: 335px;
  }

  .hero__text br {
    display: none;
  }

  .hero__cta {
    margin-right: 0;
    text-align: center;
    max-width: 219px;
  }

  .hero__title-text--mobile {
    display: block;
  }

  .hero__title-text:nth-child(1) .hero__title-text--mobile {
    text-align: right;
  }

  .hero__title-text:nth-child(2) {
    text-align: left;
  }

  .hero__title-text:nth-child(2) .hero__title-text--mobile {
    text-align: center;
  }

  .hero__title-text:nth-child(3) .hero__title-text--mobile {
    text-align: center;
  }

  .hero__image {
    border-radius: 11px;
  }

  .hero__image--mobile {
    display: block;
  }

  .hero__image--desktop {
    display: none;
  }

  .hero__image-container {
    max-width: 491px;
    margin: auto;
  }

  .hero__image-container > div {
    padding-top: 68.2281%;
  }
}

@keyframes hero-title-stroke-animation {
  0% {
    fill: rgba(10, 30, 76, 0);
    stroke: rgba(10, 30, 76, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 1;
  }
  70% {
    fill: rgba(10, 30, 76, 0);
    stroke: rgba(10, 30, 76, 1);
  }
  80% {
    fill: rgba(10, 30, 76, 0);
    stroke: rgba(10, 30, 76, 1);
    stroke-width: 1;
  }
  100% {
    fill: rgba(10, 30, 76, 1);
    stroke: rgba(10, 30, 76, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}

@keyframes hero-title-stroke-animation-primary {
  0% {
    fill: rgba(255, 92, 41, 0);
    stroke: rgba(255, 92, 41, 1);
    stroke-dashoffset: 25%;
    stroke-dasharray: 0 50%;
    stroke-width: 1;
  }
  70% {
    fill: rgba(255, 92, 41, 0);
    stroke: rgba(255, 92, 41, 1);
  }
  80% {
    fill: rgba(255, 92, 41, 0);
    stroke: rgba(255, 92, 41, 1);
    stroke-width: 1;
  }
  100% {
    fill: rgba(255, 92, 41, 1);
    stroke: rgba(255, 92, 41, 0);
    stroke-dashoffset: -25%;
    stroke-dasharray: 50% 0;
    stroke-width: 0;
  }
}
